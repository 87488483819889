import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'overlay'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    position: 'absolute',
    top: '60px',
    width: '700px',
    maxWidth: '90%',
    '& figure':{
      margin:'0'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      '& figure': {
        margin: '0'
      }
    }
  },
  closeBtn: {
    cursor: 'pointer',
    position: 'absolute',
    right: '10px'
  }
}));

export default function SampleReportModal({ open, onClose }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <HighlightOffIcon className={classes.closeBtn} onClick={onClose} />
            <figure>
              <img
                src={`${process.env.IMG_URL}/photos/images/web/content/history/report/history-sample-report.png`}
                alt="number plate"
                style={{ width: '100%' }}
              />
            </figure>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
