import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  statisticsContent: {
    padding: 0,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
    '& > li': {
      listStyle: 'none',
      flex: '0 0 20%',
      padding: '20px',
      borderRadius: '5px',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        float: 'left',
        marginBottom: 10,
        flex: '0 0 41%',
        minHeight: '100px',
        padding: 10,
        '& > div > p': {
          fontSize: '13px'
        }
      },
      '& > div > h4': {
        color: '#30343e',
        fontSize: 26,
        fontWeight: '600',
        margin: 0
      },
      '& > div > p': {
        color: '#30343e',
        fontSize: 15,
        margin: 0,
        lineHeight: 1.2
      }
    },
    '& .blue-graident': {
      background:
        'linear-gradient(120deg, rgba(28,152,229,0.29735644257703087) 0%, rgba(44,219,160,0.30015756302521013) 100%)'
    },
    '& .green-graident': {
      background:
        'linear-gradient(120deg, rgba(39,171,109,0.29735644257703087) 0%, rgba(219,219,78,0.30015756302521013) 100%)'
    },
    '& .pink-graident': {
      background:
        'linear-gradient(120deg, rgba(248,103,158,0.29735644257703087) 0%, rgba(255,175,168,0.30015756302521013) 100%)'
    },
    '& .purple-graident': {
      background:
        'linear-gradient(120deg, rgba(102,61,188,0.29735644257703087) 0%, rgba(233,151,251,0.30015756302521013) 100%)'
    }
  }
}));

const statisticsData = [
  {
    id: 1,
    queriesNo: '200 Mil+',
    content: 'Vehicle Data in<br />Vehicle History Database',
    bgColor: 'blue-graident'
  },
  {
    id: 2,
    queriesNo: '1000+',
    content: "RTO's Data in<br />Vehicle History Database",
    bgColor: 'green-graident'
  },
  {
    id: 3,
    queriesNo: '60 Lac',
    content: "Vehicle's Real<br />Time Updated Data",
    bgColor: 'pink-graident'
  },
  {
    id: 4,
    queriesNo: '50+',
    content: 'Unique Parameter of a<br />Vehicle',
    bgColor: 'purple-graident'
  }
];

function Statistics() {
  const classes = useStyles();
  return (
    <div className={classes.statisticsSection} id="key-highlights">
      <ul className={classes.statisticsContent}>
        {statisticsData.map((item, index) => (
          <li key={index} className={`stats-block ${item.bgColor}`}>
            <div>
              <h4>{item.queriesNo}</h4>
              <p dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Statistics;
