import React from 'react';
import { makeStyles, Container, Grid } from '@material-ui/core';
import SectionHeading from '../../../Components/common/SectionHeading';
const useStyles = makeStyles(theme => ({
  historyWorksWrapper: {
    '& .how-works': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      '& figure img': {
        width: 'auto',
        maxWidth: '120px',
        maxHeight: '80px'
      },
      '& figcaption': {
        background: '#fff',
        marginTop: '10px'
      }
    },
    '& .MuiGrid-grid-xs-12': {
      width: '99%'
    },
    [`${theme.breakpoints.down('xs')} and (orientation: portrait)`]: {
      '& .how-works': {
        display: 'block',
        overflow: 'hidden'
      },
      '& .how-works > div:nth-child(odd)': {
        textAlign: 'left',
        '& figure': {
          float: 'left',
          textAlign: 'center'
        }
      },
      '& .how-works > div:nth-child(even)': {
        textAlign: 'center',
        position: 'relative',
        right: '-55px',
        '& figure': {
          float: 'right'
        }
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& .how-works': {
        display: 'block',
        overflow: 'hidden',
        background:
          'url("https://cdn1.acedms.com/photos/images/web/beta/images/history/bg/how-works-dashed-line.png") no-repeat left top',
        backgroundSize: '96% 88%'
      },
      '& .how-works > div:nth-child(odd) figure::after': {
        right: '-71px',
        top: '100px',
        transform: 'rotate(-120deg)'
      },
      '& .how-works > div:nth-child(even) figure::after': {
        right: '89px',
        top: '200px',
        transform: 'rotate(-125deg)'
      }
    }
  },
  itemBlock: {
    textAlign: 'center',
    position: 'relative',
    '&:first-child figure::before, &:last-child figure::after': {
      display: 'none'
    },
    '& figure': {
      position: 'relative',
      '& .number': {
        backgroundColor: '#ebebeb',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto 10px auto'
      },
      '& img': {
        position: 'relative',
        zIndex: '1'
      },
      '& .img-container': {
        maxWidth: '120px',
        margin: 'auto'
      }
    },
    '& figure::before': {
      content: '" "',
      borderTop: '2px dashed #ccc',
      top: '80px',
      left: '-67%',
      width: '100%',
      height: '2px',
      display: 'block',
      position: 'absolute'
    },
    '& figure::after': {
      content: '" \\e909 "',
      fontFamily: 'history-icomoon',
      top: '70px',
      position: 'absolute',
      right: '-25%',
      zIndex: '2',
      transform: 'rotate(-140deg)',
      color: '#ebebeb',
      fontSize: '20px'
    },
    [theme.breakpoints.down('xs')]: {
      '& figure::before': {
        display: 'none'
      }
    }
  }
}));

const howItWorksData = [
  {
    id: 1,
    imgUrl:`${process.env.IMG_URL}/photos/images/web/beta/images/history/how-works/registration.png`,
    content: 'Enter Vehicle Registration No.'
  },

  {
    id: 2,
    imgUrl:`${process.env.IMG_URL}/photos/images/web/beta/images/history/how-works/certificate.png`,
    content: 'Fetch History Certificate'
  },
  {
    id: 3,
    imgUrl:`${process.env.IMG_URL}/photos/images/web/beta/images/history/how-works/download.png`,
    content: 'Download & Share'
  }
];
export default function HowHistoryWorks() {
  const classes = useStyles();
  return (
    <div className={classes.historyWorksWrapper} id="how-it-works">
      <Container maxWidth="lg" className="main-section container">
        <Grid
          spacing={0}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={10} xs={12} style={{ margin: 'auto' }}>
            <SectionHeading text="How History Works" />
            <Grid container spacing={0} className="how-works">
              {howItWorksData.map((item, index) => (
                <Grid
                  item
                  md={4}
                  xs={12}
                  key={index}
                  className={classes.itemBlock}
                >
                  <figure>
                    <div className="img-container">
                      <span className="number">{item.id}</span>
                      <img src={item.imgUrl} alt="Registration" />
                      <figcaption>{item.content}</figcaption>
                    </div>
                  </figure>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
