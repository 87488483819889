import React from 'react';
import { makeStyles, Container, Grid } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  listItems: {
    color: '#30343e',
    '& span': {
      fontWeight: '500',
      textAlign: 'center',
      marginBottom: '10px',
      display: 'block'
    },
    '& .MuiGrid-item': {
      padding: '5px 20px 5px 15px',
      fontSize: '13px'
    },
    '& .MuiGrid-item:nth-child(even)': {
      paddingRight: '0'
    },
    '& .MuiGrid-item': {
      position: 'relative',
      '& span': {
        position: 'absolute',
        left: '-10px',
        top: '8px',
        fontSize: '7px'
      }
    },
    '& .list-item': {
      fontSize: '13px',
    },
    [theme.breakpoints.down('sm')]:{
      '& .list-item': {
        marginBottom: '10px',
        paddingRight:'15px'
      },
    }
  }
}));

const benefitLists = [
  {
    id: 1,
    content: 'Ownership Details'
  },
  {
    id: 2,
    content: 'Registration Details'
  },
  {
    id: 3,
    content: 'Vehicle Summary'
  },
  {
    id: 4,
    content: 'Loan& Insurance Details'
  },
  {
    id: 5,
    content: 'PUC Details'
  },
  {
    id: 6,
    content: 'Blacklist & Challan Details'
  },
  {
    id: 7,
    content: 'Service Log & Repair Estimate'
  },
  {
    id: 8,
    content: 'Flood Damage Details'
  }
];
export default function ReportBenefitsList() {
  const classes = useStyles();
  return (
    <div className={classes.listItems}>
      <span>What You Will Get in Your Report</span>
      <Grid container spacing={1}>
        {benefitLists.map((item, index) => (
          <Grid item xs={6} key={index} className="list-item">
            <span className="d-history-check"></span>
            {item.content}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
