import React, {useRef} from 'react';
import { makeStyles, Container, Grid, Hidden } from '@material-ui/core';
import SectionHeading from '../../../Components/common/SectionHeading';
import WhyHistoryDetails from './WhyHistoryDetails';

const useStyles = makeStyles(theme => ({
  whyHistoryWrapper: {
    '& .main-section': {
      background:
        `url("https://cdn1.acedms.com/photos/images/web/beta/images/history/bg/why-history-bg.png") no-repeat left top`,
      backgroundSize: '100% 100%'
    },
    '& figure img': {
      maxWidth: '100%',
      position:'relative',
      top:'-50px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      '& .main-section': {
        background:
          `url("https://cdn1.acedms.com/photos/images/web/beta/images/history/bg/why-history-bg-msite.png") no-repeat center top`,
        backgroundSize: '200% 100%'
      },
      '& .left-column': {
        order: 2
      },
      '& .right-column': {
        order: 1
      },
      '& figure img':{
        top:'25px'
      }
    },
    [theme.breakpoints.down('xs')]: {

    }
  }
}));

export default function WhyHistory(props) {
  const classes = useStyles();
  const whyHistoryRef = useRef();
  return (
    <div id="why-history" className={classes.whyHistoryWrapper} ref={whyHistoryRef}>
      <Container maxWidth="lg" className="main-section container">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={10} xs={12}>
            <SectionHeading text="Why History" />
            <Grid container>
              <Grid item md={8} xs={12} className="left-column">
                <Hidden smDown>
                  <WhyHistoryDetails />
                </Hidden>
              </Grid>
              <Grid item md={4} xs={12} className="right-column">
                <figure>
                  <img
                    src={`${process.env.IMG_URL}/photos/images/web/beta/images/history/why-history.png`}
                    alt="why history"
                  />
                </figure>
                <Hidden mdUp>
                  <WhyHistoryDetails />
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
