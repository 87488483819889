import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  footerContainer: {
    backgroundColor: '#f8f8f8',
    padding: '20px 0',
    '& .container': {
      padding: theme.spacing(0, 1.25),
      maxWidth: '1170px',
      ['@media(min-width: 1370px)']: {
        maxWidth: '1280px'
      }
    },
    '& h6, & h5': {
      fontSize: '20px',
      fontWeight: '500',
      color: '#30343e',
      margin: '0',
      padding: '0'
    },
    '& h5': {
      marginBottom: '20px'
    },

    [theme.breakpoints.down('sm')]: {
      '& h5': {
        marginBottom: '10px'
      },
      '& .menu-items': {
        marginBottom: '20px'
      },
      '& .follow-us-wrapper': {
        marginBottom: '30px'
      }
    }
  }
}));
const FooterContent = () => {
  const classes = useStyles();
  return (
    <div
      className={classes.footerContainer}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <div className="container">
        <h6>About History</h6>
        <p>
          History is a platform that provides users with overall information
          about vehicle history. With the help of the tool, the users can check
          vehicle history of any car, bike or scooter and get the vehicle
          history report and generate a report. The report generated by History
          helps you check vehicle history online. This full vehicle history
          report is very useful for the buyers as well as sellers as it helps
          the users by providing all the details which are crucial while buying
          or selling a used automobile. It is India’s most comprehensive Car,
          Bike and Scooter History Tool that let the user know about the
          background of any used vehicle or the detailed vehicle information.
          This is the reason why the report is also coined as a vehicle
          information report.
        </p>
        <h6>Why History?</h6>
        <p>
          History is an unbiased report that reveals the overall information
          about any vehicle’s background. You can check vehicle information such
          as vehicle registration details, loan history of the vehicle, vehicle
          owner details, fitness report validity of the vehicle, how old the
          vehicle is, check if the vehicle is blacklisted, check a number of
          times the vehicle resold, check vehicle accident history free, etc. In
          this way, you get to know all the necessary details about the vehicle
          you are planning to buy. The users need to provide the vehicle
          registration number to check vehicle history and he/she can avail a
          detailed used car or used bike report.
        </p>
        <h6>How Does it Work?</h6>
        <p>
          History has data for more than 200+ million registered vehicles in
          more than 1000 Regional Transport Office across the country. This data
          has been consolidated from multiple authoritative sources with more
          than 60 lakh vehicle’s data updated in real time. To get a detailed
          report about any used car, the users need to opt for online vehicle
          registration by entering the car’s registration number; as soon as the
          registration number is provided, the user will get a vehicle history
          report.
        </p>
        <h6>Benefits of History</h6>
        <p>
          History is very beneficial to any user who is looking to buy or sell a
          used vehicle. The data provided by history helps buyers to make a more
          informed decision while buying a used car. It provides used vehicle
          history by generating vehicle history report. You can check the
          background of any vehicle and get a vehicle history report online
          within 10 seconds. This way you get quick and easy access to
          information about any kind of vehicle registered in India. It gives
          you authentic data which makes you more confident about your buying
          decision.
        </p>
        <h6>History Methodology</h6>
        <p>
          Being the most advanced and comprehensive used car and used bike
          history checker, History is based on true facts and provides the most
          genuine vehicle history report in India. This is the most authentic
          way to check vehicle registration details, as History takes the data
          from RTO vehicle information. Regional Transport Office (RTO) is the
          only Government of India organization that is authorized to maintain a
          database of vehicles for all states and union territories and provide
          reliable vehicle information in India. If you are planning to buy a
          used vehicle, you need to be well-informed about its background, and
          this is where History comes in. It provides an unbiased history report
          and gives vehicle information online about its past. History takes
          used vehicle’s registration number as an input and checks vehicle
          registration to generate a valid report for that vehicle. The vehicle
          registration report or report contains various information about the
          car or bike such as car registration details, car loan details,
          blacklist details, car ownership details, vehicle accident history and
          many other important points. This way, you can check the entire
          history of any used vehicle and get a free vehicle history report and
          accident history report.
        </p>
      </div>
    </div>
  );
};

export default FooterContent;
