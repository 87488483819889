import React from 'react';
import { makeStyles } from '@material-ui/core';
import '../Root/index.css';
const useStyles = makeStyles(theme => ({
  homeMainWrapper: {
    backgroundColor: '',
    '& .main-section': {
      paddingTop: '30px',
      paddingBottom: '30px'
    }
  }
}));
import HowHistoryWorks from './components/how-history-works/HowHistoryWorks';
import AboutHistory from './components/AboutHistory';
import Faq from './components/FAQ';
import Testimonials from './components/testimonials/Testimonials';
import WhyHistory from './components/why-history/WhyHistory';
import HistoryBenefits from './components/history-benefits/HistoryBenefits';
import Banner from './components/main-banner/MainBanner';
import AboutNumberPlate from './components/know-number-plates/AboutNumberPlate';
import FooterContent from '../Components/Footer/FooterContent';
export default function Home() {
  const classes = useStyles();

  return (
    <div className={classes.homeMainWrapper}>
      <Banner />
      <AboutHistory />
      <WhyHistory />
      <HowHistoryWorks />
      <HistoryBenefits />
      <Testimonials />
      <AboutNumberPlate />
      <Faq />
      <FooterContent />
    </div>
  );
}
