import React from 'react';
import { makeStyles, Container, Grid } from '@material-ui/core';
import SectionHeading from '../../Components/common/SectionHeading';
const useStyles = makeStyles(theme => ({
  aboutHistoryWrapper: {
    color: '#30343e',
    '& .banner-details': {
      '& ul': {
        listStyle: 'none'
      },
      '& li:nth-child(1)': {
        marginTop: '0'
      },
      '& .banner-image': {
        background:
          `url(${process.env.IMG_URL}/photos/images/web/beta/images/history/about-history/car-bg.png) no-repeat center top`,
        backgroundSize: '140% 100%',
        textAlign: 'center',
        margin: '0',
        '& img': {
          maxWidth: '300px'
        }
      }
    },
    '& .content-block': {
      fontSize: '16px',
      color: '#30343e',
      paddingLeft: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      '& .text-center': {
        textAlign: 'center'
      }
    }
  },
  listingItem: {
    marginTop: '40px'
  },
  iconBlock: {
    maxWidth: '85px',
    height: '60px',
    background: '#fff',
    borderRadius: '5px',
    borderCollapse: '',
    boxShadow: '1px 2px 5px rgba(0,0,0,.3)',
    padding: '10px',
    textAlign: 'center',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '36px',
    '&::before, &::after': {
      content: '" "',
      borderStyle: 'solid',
      borderColor: '#2598fb',
      width: '10px',
      height: '10px',
      position: 'absolute'
    },
    '&::before': {
      borderWidth: '1px 0 0 1px',
      top: '0',
      left: '0',
      borderTopLeftRadius: '5px'
    },
    '&::after': {
      borderWidth: '0 1px 1px 0',
      bottom: '0',
      right: '0',
      borderBottomRightRadius: '5px'
    }
  }
}));
const historyData = [
  {
    id: 1,
    icon: '<span class="d-history-history-check"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>',
    content: "India's Most Comprehensive Vehicle History Check"
  },
  {
    id: 2,
    icon: '<span class="d-history-history-count"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>',
    content: "Provides 200Mil+ Vehicle's History"
  },
  {
    id: 3,
    icon: '<span class="d-history-technology"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>',
    content: "Build Using Droom's Proprietary Technology"
  }
];
export default function AboutHistory() {
  const classes = useStyles();
  return (
    <div className={classes.aboutHistoryWrapper} id="about-history">
      <Container maxWidth="lg" className="main-section container">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={10} xs={12}>
            <SectionHeading text="About History" />
            <p className="text-center">
              History is India's largest national repository for vehicle history
              that offers used history report for nearly 200 Million of the
              total 220 Million registered vehicles in India. History report
              provides overall information about a vehicle's background,
              covering up to 50 different history records in 10 seconds.
              
            </p>
            <Grid container className="banner-details">
              <Grid item md={7} xs={12}>
                <figure className="banner-image">
                  <img
                    src="https://cdn1.acedms.com/photos/images/web/beta/images/history/about-history/car.png"
                    alt="about history"
                  />
                </figure>
              </Grid>
              <Grid item md={5} xs={12}>
                <ul>
                  {historyData.map((item, index) => (
                    <li className={classes.listingItem} key={index}>
                      <Grid container>
                        <Grid item sm={2} xs={3}>
                          <span
                            className={classes.iconBlock}
                            dangerouslySetInnerHTML={{ __html: item.icon }}
                          ></span>
                        </Grid>
                        <Grid item sm={10} xs={9}>
                          <div className="content-block">{item.content}</div>
                        </Grid>
                      </Grid>
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
