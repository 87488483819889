import React from 'react';
import { makeStyles, Container, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import TestimonialSlider from './TestimonialSlider';
import SectionHeading from '../../../Components/common/SectionHeading';

const useStyles = makeStyles(theme => ({
  testimonialSection: {
    padding: '0',
    background: '#f9f9f9',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      textAlign: 'center'
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
      '& button': {
        fontFamily: "'Cera Pro', sans-serif",
        minWidth: 'auto',
        backgroundColor: '#4da424',
        opacity: '1',
        color: '#fff',
        fontSize: '16px',
        padding: '5px 25px',
        minHeight: 'auto'
      },
      '& button.Mui-selected': {
        backgroundColor: '#2699f9',
        boxShadow: '0 2px 5px rgba(0,0,0,.5)'
      },
      '& button:first-child': {
        borderTopLeftRadius: '50px',
        borderBottomLeftRadius: '50px'
      },
      '& button:last-child': {
        borderTopRightRadius: '50px',
        borderBottomRightRadius: '50px',
        marginLeft: '3px'
      }
    }
  }
}));
const buyerTestimonials = [
  {
    id: 1,
    userImg: `${process.env.IMG_URL}/photos/images/web/content/history/1e.webp`,
    quoteSign: `${process.env.IMG_URL}/photos/images/web/beta/images/dss/testimonial-icon.svg`,
    userTestimonial:
      'At first, I was really worried about my used vehicle purchase. I could see its present condition, but, at the same time, I wanted to know if something serious happened to it before. With the help of History, now I can see the history of the vehicle I am going to buy.',
    author: 'Jatin Mangal',
    designation: ''
  },
  {
    id: 2,
    userImg: `${process.env.IMG_URL}/photos/images/web/content/history/2e.webp`,
    quoteSign: `${process.env.IMG_URL}/photos/images/web/beta/images/dss/testimonial-icon.svg`,
    userTestimonial:
      'History comes as a boon for the used vehicle buyers. It provides a detailed certificate of the vehicle. For me, it cleared all the confusion I had in my mind while buying a second-hand car.',
    author: 'Mandeep Singh',
    designation: ''
  },
  {
    id: 3,
    userImg: `${process.env.IMG_URL}/photos/images/web/content/history/3e.webp`,
    quoteSign: `${process.env.IMG_URL}/photos/images/web/beta/images/dss/testimonial-icon.svg`,
    userTestimonial:
      'I entered the registration number of the vehicle I want to purchase on the History website and it gave me a proper certificate of the vehicle that includes unbiased remarks and tells the real condition of any vehicle.',
    author: 'Ranjeet Singh',
    designation: ''
  },
  {
    id: 4,
    userImg: `${process.env.IMG_URL}/photos/images/web/content/history/4e.webp`,
    quoteSign: `${process.env.IMG_URL}/photos/images/web/beta/images/dss/testimonial-icon.svg`,
    userTestimonial:
      'As a used vehicle buyer, I can say that History is very helpful in buying any second-hand vehicle as it shows you the true condition of any vehicle. This creates transparency.',
    author: 'Vikram Tanwar',
    designation: ''
  }
];
const sellerTestimonials = [
  {
    id: 1,
    userImg: `${process.env.IMG_URL}/photos/images/web/content/history/bhagwatie.webp`,
    quoteSign: `${process.env.IMG_URL}/photos/images/web/beta/images/dss/testimonial-icon.svg`,
    userTestimonial:
      'Droom has created some amazing tools for the seller community which have helped us sell faster and more easily to buyers. Droom has given us their Full Circle Trust Score, Eco certificates for inspections, OBV for pricing and now History for vehicle data on loans, police record, ownership history etc.',
    author: 'Nikhil Gupta',
    designation: ''
  },
  {
    id: 2,
    userImg: `${process.env.IMG_URL}photos/images/web/content/history/auto-beste.webp`,
    quoteSign: `${process.env.IMG_URL}/photos/images/web/beta/images/dss/testimonial-icon.svg`,
    userTestimonial:
      "History helps both buyers and sellers as buyers can now clearly see the full log of a vehicle including if it's accidental, has a loan on it and has insurance or not",
    author: 'Hunny Singh',
    designation: ''
  }
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`testimonials-tabpanel-${index}`}
      aria-labelledby={`testimonials-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `testimonials-tab-${index}`,
    'aria-controls': `testimonials-tabpanel-${index}`
  };
}
function Testimonials() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.testimonialSection}>
      <Container maxWidth="lg" className="main-section container">
        <SectionHeading text="Testimonials" />
        <div className="testimonials-wrapper">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="testimonials tabs"
            className="tabs-panel"
          >
            <Tab label="Buyer" {...a11yProps(0)} />
            <Tab label="Seller" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <TestimonialSlider data={buyerTestimonials} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TestimonialSlider data={sellerTestimonials} />
          </TabPanel>
        </div>
      </Container>
    </div>
  );
}

export default Testimonials;
