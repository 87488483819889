import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  benefitsKeyPoints: {
    position: 'relative',
    '& .detail-block': {
      padding: '20px 10px',
      display: 'flex',
      maxWidth: '300px',
      '& .icon': {
        width: '25px',
        color: '#2598fb',
        marginRight: '10px'
      },
      '& .content': {
        width: 'calc(100% - 25px)'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      }
    }
  },
  itemBlock: {
    width: '100%',
    margin: '0',
    padding: '0',
    position: 'absolute',
    top: '0',
    left: '0',
    minHeight: '200px',
    listStyle: 'none',
    '& li': {
      position: 'absolute',
      width: '50%'
    },
    '& li:nth-child(1)': {
      left: '40px',
      '& div': {
        float: 'left'
      }
    },
    '& li:nth-child(2)': {
      right: '40px',
      '& div': {
        float: 'right'
      }
    },
    '& li:nth-child(3)': {
      left: '0',
      bottom: '0',
      '& div': {
        float: 'left'
      }
    },
    '& li:nth-child(4)': {
      right: '0',
      bottom: '0',
      '& div': {
        float: 'right'
      }
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      '& li': {
        position: 'relative',
        width: '100%',
        marginTop: '20px',
        left: '0!important',
        '& div': {
          float: 'inherit!important'
        }
      },
      '& li:first-child': {
        marginTop: '0'
      }
    }
  }
}));
const whyHistoryKeyPoints = [
  {
    id: 1,
    content: "Check Any Vehicle's History online with in a minute."
  },
  {
    id: 2,
    content:
      'Quick and easy access to information about any kind of vehicle registered in India'
  },
  {
    id: 3,
    content:
      'Comprehensive and consolidated Vehicle history from multiple authoritative sources'
  },
  {
    id: 4,
    content:
      'Authentic data to help you make more informed decision in vehicle sale or purchase'
  }
];

export default function BenefitsHistoryDetails() {
  const classes = useStyles();
  return (
    <div className={classes.benefitsKeyPoints}>
      <ul className={classes.itemBlock}>
        {whyHistoryKeyPoints.map((item, index) => (
          <li key={index}>
            <div className="detail-block">
              <span className="icon">
                <CheckCircleIcon />
              </span>
              <div className="content">{item.content}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
