import React from 'react';
import { makeStyles, Container, Grid, Hidden } from '@material-ui/core';
import SectionHeading from '../../../Components/common/SectionHeading';
import NumberPlateGallery from './NumberPlateGallery';

const useStyles = makeStyles(theme => ({
  aboutNumberPlateWrapper: {
    '& .main-section': {
      background:
        `url(${process.env.IMG_URL}/photos/images/web/beta/images/history/bg/number-plate-bg-web.png) no-repeat left top`,
      backgroundSize: '100% 100%'
    },
    '& .img-container': {
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      }
    },
    '& figure img': {
      maxWidth: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inherit',
      '& .main-section': {
        background:
          'url(https://cdn1.acedms.com/photos/images/web/beta/images/history/bg/number-plate-bg-msite.png) no-repeat center top',
        backgroundSize: '200% 100%'
      }
    }
  }
}));

export default function AboutNumberPlate() {
  const classes = useStyles();
  return (
    <div className={classes.aboutNumberPlateWrapper} id="know-number-plate">
      <Container maxWidth="lg" className="main-section container">
        <SectionHeading text="Know About Number plates" />
        <div className="img-container">
          <figure>
            <Hidden smDown>

              <img
                src={`${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plate-web.png`}
                alt="number plate"
              />
            </Hidden>
            <Hidden mdUp>
              <img
                src={`${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plate-msite.png`}
                alt="number plate"
              />
            </Hidden>
          </figure>
        </div>
        <NumberPlateGallery />
      </Container>
    </div>
  );
}
