import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  InputAdornment,
  TextField,
  Button
} from '@material-ui/core';
import ReportBenefitsList from './ReportBenefitsList';
import { useHistory } from 'react-router-dom';
import SampleReportModal from '../../../Components/common/SampleReportModal';
import Disclaimer from './Disclaimer';
import { changeValue, resetValue } from '@redux/reducers/panelSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
const useStyles = makeStyles(theme => ({
  formWrapper: {
    backgroundImage: `url(${process.env.IMG_URL}/photos/images/web/beta/images/history/form-bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center -50px',
    backgroundSize: '100% 110%',
    padding: '20px 20px 20px 80px',
    minHeight: '400px',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 20px 50px 50px',
      backgroundSize: 'cover',
      backgroundPosition: 'right top'
    },
    '& .heading': {
      fontSize: 22,
      fontWeight: '600',
      textAlign: 'center',
      marginTop: '0'
    },
    '& .submitBtn': {
      borderRadius: '20px',
      marginTop: '10px',
      backgroundColor: '#2598fb',
      padding: '10px'
    },
    '& .inputField': {
      background: '#fff',
      '& input': {
        padding: '6px 0 7px'
      },
      '& .security-hologram': {
        width: '16px',
        margin: 'auto',
        '& img': {
          width: '100%'
        }
      }
    },
    '& .MuiOutlinedInput-adornedStart': {
      padding: '0'
    },
    '& .countryIcon': {
      background: '#0c256d',
      display: 'inline-table',
      padding: '5px 10px',
      textAlign: 'center',
      color: '#fff',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      '& svg': {
        width: '20px',
        height: '20px'
      },
      '& span': {
        display: 'block',
        fontSize: '12px'
      }
    },
    '& .sample-report-btn': {
      textAlign: 'center',
      display: 'block',
      margin: '10px auto',
      color: '#2598fb',
      textTransform: 'capitalize'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-15px',
      marginRight: '-15px',
      backgroundPosition: 'center top',
      backgroundSize: '130% 100%',
      padding: '40px',
      paddingTop: '50px',
      '& .inputField': {
        '& input': {
          fontSize: '11px'
        }
      }
    }
  }
}));

export default function HistoryForm() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { authenticated } = useSelector(state => state.auth);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [errorMsg, setErrorMsg] = useState(false);
  const [formSubm, setFormSubm] = useState(false);

  const pattern = new RegExp(/^[a-zA-z]{2}[0-9a-zA-Z]{0,6}[0-9]{4}$/);
  useEffect(() => {
    if (authenticated && formSubm) {
      history.push('/checkout');
    }
  }, [authenticated, formSubm, history]);
  const handleOpen = scrollType => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changePanelValue = () => {
    dispatch(resetValue());
  };

  const submitHandler = async e => {
    e.preventDefault();
    if (registrationNumber === '') {
      setErrorMsg(true);
    } else if (pattern.test(registrationNumber)) {
      sessionStorage.setItem('rc_no', registrationNumber);
      await axios.get(
        `/drapi/vehicle/search?source=history_web&registration_number=${registrationNumber}&api_token=`
      );
      if (authenticated) {
        history.push('/checkout');
      } else {
        dispatch(changeValue());
        setTimeout(changePanelValue, 1000);
        setFormSubm(true);
      }
    } else {
      setErrorMsg(true);
    }
  };
  const registrationNumberHandler = e => {
    e.preventDefault();
    setErrorMsg(false);
    let rawData = e.target.value;
    rawData = rawData.toUpperCase();
    setRegistrationNumber(rawData);
  };
  return (
    <>
      <div className={classes.formWrapper}>
        <h1 className="heading">Check Any Vehicle History in 10 Seconds</h1>
        <Grid container>
          <Grid item md={9} xs={12} style={{ margin: 'auto' }}>
            <form name="history-query-form">
              <div className="inputField">
                <TextField
                  id="outlined-basic"
                  placeholder="Enter Valid RC Number Ex. DL3CB74XX"
                  variant="outlined"
                  className=""
                  value={registrationNumber}
                  onChange={registrationNumberHandler}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="countryIcon">
                        <figure className="security-hologram">
                          <img
                            src={`${process.env.IMG_URL}/photos/images/web/beta/images/history/security-hologram.png`}
                            alt="hologram"
                          />
                        </figure>
                        <span>IND</span>
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </div>
              {errorMsg && (
                <span style={{ color: 'red' }}>
                  Please Enter A Valid Registration Number
                </span>
              )}
              <Button
                className="submitBtn"
                variant="contained"
                color="primary"
                fullWidth
                onClick={submitHandler}
              >
                Buy History Report @ 129/-
              </Button>
            </form>
          </Grid>
          <Grid item md={10} style={{ margin: 'auto' }}>
            <Button
              color="primary"
              className="sample-report-btn"
              onClick={() => handleOpen()}
            >
              View Sample report
            </Button>
            <ReportBenefitsList />
          </Grid>
        </Grid>
        <div className="reportModalWrapper">
          <SampleReportModal
            open={open}
            scroll={scroll}
            onClose={handleClose}
          />
        </div>
      </div>
      <Disclaimer />
    </>
  );
}
