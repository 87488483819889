import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

const useStyles = makeStyles(theme => ({
  historyKeyPoints: {
    '& .detail-block': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: '5px',
      padding: '20px 10px',
      display: 'flex',
      minHeight: '40px',
      boxShadow: '0 0 5px rgba(0,0,0,0.2)',
      '& .icon': {
        width: '25px',
        color: 'green',
        fontSize: '14px',
        paddingTop: '3px'
      },
      '& .content': {
        width: 'calc(100% - 25px)'
      }
    }
  }
  // itemBlock:{
  //     '& .MuiGrid-item:nth-child(odd)':{
  //         float:'right',
  //         border:'1px solid red'
  //     },
  //     '& .MuiGrid-item:nth-child(even)':{
  //         float:'left',
  //         border:'1px solid blue'
  //     }
  // }
}));
const whyHistoryKeyPoints = [
  {
    id: 1,
    content:
      'Check authenticity of the vehicle information provided by the seller'
  },
  {
    id: 2,
    content: 'Check if the vehicle is blacklisted'
  },
  {
    id: 3,
    content: 'Check number of times the vehicle has been resold'
  },
  {
    id: 4,
    content: 'Check how old the vehicle is'
  },
  {
    id: 5,
    content: 'Check vehicle fitness certificate validity'
  },
  {
    id: 6,
    content: 'Check loan history of the vehicle'
  }
];

export default function WhyHistoryDetails() {
  const classes = useStyles();
  return (
    <div className={classes.historyKeyPoints}>
      <Grid container spacing={3} className={classes.itemBlock}>
        {whyHistoryKeyPoints.map((item, index) => (
          <Grid item key={index} md={6} xs={12}>
            <div className="detail-block">
              <span className="icon">
                <span className="d-history-check"></span>
              </span>
              <div className="content">{item.content}</div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
