import React from 'react';
import { makeStyles, Container, Grid, Hidden } from '@material-ui/core';
import SectionHeading from '../../../Components/common/SectionHeading';
import BenefitsHistoryDetails from './BenefitsHistoryDetails';


const useStyles = makeStyles(theme => ({
  whyHistoryWrapper: {
    '& .main-section': {
      background:
          `url(${process.env.IMG_URL}/photos/images/web/beta/images/history/bg/why-history-bg.png) no-repeat left top`,
      backgroundSize: '100% 100%'
    },
    '& .img-container': {
      maxWidth: '300px',
      margin: 'auto',
      '& figure': {
        textAlign: 'center'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      }
    },
    '& figure img': {
      maxWidth: '100%'
    },
    '& .left-column': {
      width: '80%',
      margin: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      '& .main-section': {
        background:
          `url(${process.env.IMG_URL}/photos/images/web/beta/images/history/bg/why-history-bg-msite.png) no-repeat center top`,
        backgroundSize: '200% 100%'
      },
      '& .left-column': {
        order: 2
      },
      '& .right-column': {
        order: 1
      }
    }
  }
}));

export default function HistoryBenefits() {
  const classes = useStyles();
  return (
    <div className={classes.whyHistoryWrapper}>
      <Container maxWidth="lg" className="main-section container">
        <div>
          <SectionHeading text="Benefits of History Certificate" />
          <div>
            <div className="left-column">
              <Hidden smDown>
                <BenefitsHistoryDetails />
              </Hidden>
            </div>
            <div className="right-column img-container">
              <figure>
                <img
                  src={`${process.env.IMG_URL}/photos/images/web/beta/images/history/benefits/history-benefits.png`}
                  alt="history benefits"
                />
              </figure>
              <Hidden mdUp>
                <BenefitsHistoryDetails />
              </Hidden>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
