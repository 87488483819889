import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import SectionHeading from '../../Components/common/SectionHeading';

const useStyles = makeStyles(theme => ({
  faqSection: {
    padding: '40px 0',
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 60
    }
  },
  faqHeading: {
    color: '#191a1b',
    fontSize: 28,
    margin: 0,
    fontWeight: '500',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      textAlign: 'center'
    }
  },
  faqContentPanel: {
    '& .MuiAccordionSummary-content > h4': {
      margin: 0,
      fontWeight: '500',
      color: '#191a1b',
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14
      }
    },
    '& .MuiAccordionDetails-root > p': {
      color: '#5c5c5c',
      margin: 0,
      fontSize: 14
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
      paddingBottom: 20
    },
    '& .MuiAccordionSummary-root': {
      padding: 0
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
      borderBottom: '1px solid #e5e5e5'
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48
    },
    '& .MuiAccordion-root:before': {
      display: 'none'
    },
    '& .MuiAccordion-root': {
      marginBottom: 10,
      marginTop: 10
    },
    '& .faqs-list-item': {
      margin: 0
    }
  }
}));
const faqData = [
  {
    id: 1,
    faqHeading: 'Can I sell Vehicle History report ?',
    faqContent:
      "No, Droom reserves all the rights to sell the History report. End user(s) can use vehicle's history report for personal reference while buying or selling a vehicle. Droom may take a legal action against anyone found misusing the report."
  },
  {
    id: 2,
    faqHeading: 'How is a Droom Vehicle Report helpful?',
    faqContent:
      "Vehicle History report helps in taking an informed decision before purchasing a used vehicle. It provides important information about vehicle's background, covering up to 50 different history records that can save you from being a victim to a fraud."
  },
  {
    id: 3,
    faqHeading:
      'Why is the Vehicle History report I am looking for not available on the website?',
    faqContent:
      'There is a possibility that currently the report is not available in our database. We are constantly increasing our database to provide users with History report of every used vehicle. We request you to try after some time to see the availability of the report.'
  },
  {
    id: 4,
    faqHeading: 'How will I receive my Vehicle History report?',
    faqContent:
      'Your Vehicle History report will be available on the result page. You can directly download the history report using a link provided at end of the process. A detailed result will also be sent to your e-mail id in up to 30 minutes.'
  },
  {
    id: 5,
    faqHeading: 'What is the cost of History report?',
    faqContent:
      'There is nominal fee for report & ₹129 for History Premium report.'
  }
];
export default function Faq() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className={classes.faqSection}>
      <Container maxWidth="lg" className="main-section container">
        <a name="faq"></a>
        <div className="container">
          <SectionHeading text="FAQs" />
          <div className={classes.faqContentPanel}>
            {faqData.map((item, index) => (
              <Accordion
                expanded={expanded === `panel${item.id}`}
                onChange={handleChange(`panel${item.id}`)}
                key={index}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={
                    expanded === `panel${item.id}` ? (
                      <RemoveIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                >
                  <h4>{item.faqHeading}</h4>
                </AccordionSummary>
                <AccordionDetails>
                  {item.collectionType === 'itemList' ? (
                    <ul className="faqs-list-item">
                      {item.faqContent.map((listItem, i) => (
                        <li key={i}>{listItem.content}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{item.faqContent}</p>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}
