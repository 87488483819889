import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import Slider from 'react-slick';
import './styles.css';
const useStyles = makeStyles(theme => ({
  sliderWrapper: {
    backgroundColor: 'rgba(255,255,255,0.1)',
    borderRadius: '10px',
    padding: '20px 50px',
    boxShadow: '0 0 10px rgba(0,0,0,.1)',
    '& .slides ': {
      textAlign: 'center',
      color: '#30343e',
      '& h4': {
        fontSize: '17px',
        fontWeight: '500',
        marginBottom: '10px'
      },
      '& figure img': {
        margin: '0 auto',
        maxWidth: '550px',
        width: '100%'
      }
    },
    '& .listing': {
      listStylePosition: 'outside',
      listStyleType: 'disc',
      display: 'inline-block',
      textAlign: 'left',
      marginLeft:'20px'
    },
    '& .slick-prev:before': {
      content: '" \\e923 "'
    },
    '& .slick-next:before': {
      content: '" \\e924 "'
    },
    '& .slick-next:before, & .slick-prev:before': {
      fontFamily: 'history-icomoon',
      color: '#000'
    },
    '& .slick-next.slick-disabled:before, & .slick-prev.slick-disabled:before':
      {
        opacity: '.25'
      },
    '& .slick-prev': {
      left: '-40px'
    },
    '& .slick-next': {
      right: '-40px'
    },
    '& .thumb-slides figure img':{
      width:'90%',
    },
    '& figure':{
      marginBottom:'10px!important'
    },
    '& .slick-dots':{
      bottom:'-60px',
      position:'absolute'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-15px',
      marginRight: '-15px',
      background: '#fff',
      borderRadius: '0',
      padding:'20px',
      height:'auto',
      '& .thumbs-slides-wrapper .slick-list':{
        display:'none'
      }
    }
  }
}));

const imagesGallery = [
  {
    id: 1,
    headingText: 'White Number Plate',
    originalImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/white.png`,
    thumbnailImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/white.png`,
    content:
      'This type of number plate is one of the most common types of vehicle registration plate. It is a white plate with black lettering on it. Furthermore, it is used only for private purposes and cannot be used for commercial purposes like ferrying passengers and transporting goods.'
  },
  {
    id: 2,
    headingText: 'Yellow Number Plate',
    originalImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/yellow.png`,
    thumbnailImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/yellow.png`,
    content:
      'Talking about this number plate, it is a plate with a black text pattern. This type of vehicle registration plate can only be used for commercial purposes. Also, the driver should have a commercial driving permit to drive such a vehicle. The cabs, rickshaws, and other goods carriers fall under this category.'
  },
  {
    id: 3,
    headingText: 'Green Number Plate',
    originalImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/green.png`,
    thumbnailImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/green.png`,
    content:
      'The green number plate is associated with electric vehicles. It is a green color plate with white lettering on it. Also, these types of vehicles are powered by electricity. If you see a vehicle with a green number plate, this can either be for private or commercial use.<ul class="listing"><li>If the font color is white on the green plate then it is used for private purposes.</li><li>If the font color is yellow on the green plate then it is used for commercial purposes.</li></ul>'
  },
  {
    id: 4,
    headingText: 'Red Number Plate',
    originalImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/red.png`,
    thumbnailImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/red.png`,
    content:
      'This number plate is mostly seen on brand-new vehicles. It is a temporary vehicle registration plate that is yet to get a permanent one issued by the RTO. Furthermore, temporary vehicle registration comes with a 1-month validity period. The rules for the vehicle with the red number plate differ from state to state.'
  },
  {
    id: 5,
    headingText: 'Blue Number Plate',
    originalImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/blue.png`,
    thumbnailImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/blue.png`,
    content:
      'A vehicle with a blue number plate depicts that it has been issued by the respective authority to reserve for foreign diplomats. It is a blue-colored plate with white lettering on it. Moving on, this type of number plate bears alphabets like DC(Diplomatic Corps), CC(Consular Corps), and UN(United Nations). Besides these, the number plate does not bear a state code. Instead, it comes with the country code of the diplomat.'
  },
  {
    id: 6,
    headingText: 'Black Number Plate',
    originalImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/black.png`,
    thumbnailImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/black.png`,
    content:
      'This type of plate is black in color with yellow lettering on it. It is mostly used for rentals or by luxury hotels for transport.'
  },
  {
    id: 7,
    headingText: 'Number Plate with an Upward Pointing Arrow',
    originalImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/up-arrow.png`,
    thumbnailImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/up-arrow.png`,
    content:
      'Such number plates are mostly used on military vehicles. The numbers on the plate are registered under the Ministry of Defense. These plates usually have a green or black background. The first and third upward pointing arrows are known as broad arrows. These arrows are used in many parts of the British Commonwealth. Talking about the two digits after the arrow, they depict the year when the military vehicle was procured. This is followed by a base code and a serial number. Lastly, there is a penultimate letter that refers to the class of the vehicle.'
  },
  {
    id: 8,
    headingText: 'Red Number Plate with the Emblem of India',
    originalImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/red-with-sign.png`,
    thumbnailImg: `${process.env.IMG_URL}/photos/images/web/beta/images/history/number-plates/thumbs/red-with-sign.png`,
    content:
      'This type of number plate is very special. It is red in color with the Emblem of India branded in golden color. Such number plates belong to the President of India or the Governors of various states.'
  }
];

export default function NumberPlateGallery() {
  const classes = useStyles();
  const [state, setState] = useState({
    nav1: null,
    nav2: null
  });
  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current
    });
  }, []);
  const { nav1, nav2 } = state;
  const settingsSliderThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    focusOnSelect: true,
    infinite: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false
        }
      }
    ]
  };
  const settingsSliderNav = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    focusOnSelect: true,
    infinite: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false
        }
      }
    ]
  };
  return (
    <div className={`${classes.sliderWrapper}`}>
      <div className="slider-wrapper">
        <div className="original-slides-wrapper">
          <Slider
            asNavFor={nav2}
            ref={slider => (slider1.current = slider)}
            {...settingsSliderNav}
          >
            {imagesGallery.map((item, index) => {
              return (
                <div className="slides" key={index}>
                  <h4>{item.headingText}</h4>
                  <figure>
                    <img src={item.originalImg} alt="number plate" />
                  </figure>
                  <figcaption
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="thumbs-slides-wrapper">
          <Slider
            asNavFor={nav1}
            ref={slider => (slider2.current = slider)}
            {...settingsSliderThumbs}
          >
            {imagesGallery.map((item, index) => {
              return (
                <div className="thumb-slides" key={index}>
                  <figure>
                    <img src={item.originalImg} alt="number plate" />
                  </figure>
                  <figcaption>{item.headingText}</figcaption>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
