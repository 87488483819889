import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },

  dislciamerWrapper: {
    position: 'relative',
    '& .dislciamer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      paddingRight: '20px',
      float: 'right',
      '& svg': {
        color: '#a2adbd'
      },
      '& span': {
        paddingLeft: '5px',
        cursor: 'pointer',
        color: '#2598fb'
      }
    }
  },
  disclaimerModal: {
    width: '350px',
    maxWidth: '90%',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: '5px',
    padding: '10px',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    top: '25px',
    right: '0',
    '& svg': {
      color: '#a2adbd',
      float: 'right',
      cursor: 'pointer'
    }
  }
}));

export default function DisclaimerToggle() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <div className={classes.dislciamerWrapper}>
      <div className="dislciamer" onClick={handleClick}>
        <ReportProblemIcon />
        <span>Disclaimer</span>
      </div>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <div className={classes.root}>
          {open ? (
            <div className={classes.disclaimerModal}>
              <HighlightOffIcon onClick={handleClickAway} />
              <p>
                Note: Vehicle History report is generated from hundreds of
                disparate sources. It is possible that for some vehicles we may
                not be able to present entire information because of
                non-availability of data points at the sources and Droom does
                not have any way to inform about the data availability in
                advance. Please note that this is a non-refundable transaction
                and order cancelation is not allowed.
              </p>
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
}
