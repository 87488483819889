import React from 'react';
import { makeStyles, Container, Grid } from '@material-ui/core';
import HistoryForm from './HistoryForm';
import Statistics from './Statistics';
const useStyles = makeStyles(theme => ({
  bannerMainWrapper: {
    '& .banner-container': {
      marginBottom: '20px',
      '& .main-banner-image': {
        margin: '0',
        textAlign: 'center',
        '& img': {
          maxWidth: '100%',
          position: 'relative'
        }
      },
      [theme.breakpoints.up('sm')]: {
        '& .main-banner-image': {
          '& img': {
            
          }
        }
      }
    },
    '& .main-section': {
      paddingTop: '0!important'
    }
  }
}));

export default function Banner() {
  const classes = useStyles();
  return (
    <div className={classes.bannerMainWrapper}>
      <Container maxWidth="lg" className="main-section container">
        <Grid container className="banner-container">
          <Grid item md={6} xs={12}>
            <figure className="main-banner-image">
              <img
                src={`${process.env.IMG_URL}/photos/images/web/beta/images/history/banner-image-new.png`}
                alt="Vehicle History Details"
              />
            </figure>
          </Grid>
          <Grid item md={6} xs={12}>
            <HistoryForm />
          </Grid>
        </Grid>
        <Statistics />
      </Container>
    </div>
  );
}
