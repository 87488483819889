import React from 'react';
import { makeStyles } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const useStyles = makeStyles(theme => ({
  testimonialSlider: {
    marginTop: 20
  },
  testimonialData: {
    textAlign: 'center',
    padding:'0 50px',
    '& > p': {
      color: '#5c5c5c',
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: 13
      }
    },
    '& > h5': {
      fontWeight: '500',
      color: '#191a1b',
      fontSize: 16,
      marginBottom: 0
    },
    '& > span': {
      color: '#a6a6a6',
      fontSize: 30,
      marginTop:'20px',
      display:'inline-block'
    },
    '& figure img': {
      borderRadius: '50%',
      boxShadow: '0 0 5px rgba(0,0,0,.5)'
    }
  },
  testimonialIcon: {
    width: 45,
    marginTop: 15,
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      width: 23
    }
  }
}));

function TestimonialSlider(props) {
  const classes = useStyles();
  const options = {
    items: 1
  };
  return (
    <div className={classes.testimonialSlider}>
      <AliceCarousel {...options}>
        {props.data.map((item, index) => (
          <div className="item" key={index}>
            <div className={classes.testimonialData}>
              <figure>
                <img src={item.userImg} alt={item.id} />
              </figure>
              <span
                className={`d-history-quote ${classes.testimonialIcon}`}
              ></span>
              <p>{item.userTestimonial}</p>
              <h5>{item.author}</h5>
              <span>{item.designation}</span>
            </div>
          </div>
        ))}
      </AliceCarousel>
    </div>
  );
}

export default TestimonialSlider;
