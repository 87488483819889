import React from 'react';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    margin: '0 auto'
  },
  sectionHeading: {
    fontSize: '24px',
    color: '#30343e',
    textAlign: 'center',
    margin: '0 0 10px',
    fontWeight: '500',
    position: 'relative',
    display: 'inline-block',
    '& span': {
      padding: '0 10px',
      // backgroundColor: '#fff',
      position: 'relative',
      zIndex: '1'
    },
    '&::before, &::after': {
      position: 'absolute',
      top: '51%',
      overflow: 'hidden',
      width: '50%',
      height: '1px',
      content: '" "',
      backgroundColor:'#6f727b',
    },
    '&::before': {
      // content: '" "',
      // height: '3px',
      // borderTop: '1px solid #6f727b',
      // position: 'absolute',
      // width: '150%',
      // top: '50%',
      // left: '-25%',
      marginLeft: '-50%',
      textAlign: 'right',
      zIndex: '0'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px',
      '&::before, &::after': {
        width: '10%',
      },
      '&::before': {
        marginLeft: '-10%'
      },
      '&::after': {
        marginRight: '-10%'
      }
    }
  }
}));

export default function SectionHeading(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h2 className={classes.sectionHeading}>
        <span>{props.text}</span>
      </h2>
    </div>
  );
}
